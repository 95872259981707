import React, { useState, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";
import ThreadBox from "./Boxes/ThreadBox";
import ThreadBoxContent from "./ThreadBoxContent";

// Material UI
import { List, useMediaQuery } from "@material-ui/core";

// Util
import { addHighlight, getOffsetCalc, getSelection, newLineToBreak } from "./Util/AnnotationHelpers";
import { send_request_graphql } from "./Request";
import { CREATE_ANNOTATION_AND_RETURN_ALL, GET_ALL_ANNOTATIONS } from "./Util/GraphQLQueries";
import { InsideDiv, StyledDrawer, StyledSkeleton } from "./Util/AnnotationStyledComponents";

function ThreadSidebar({ isOpen, showSidebarAndHighlight, highlightedAnnotation, username, timezone, props, rerenderSidebar, addAnnotationHighlights, annotationCount, updateState }) {
    const isLargerScreen = useMediaQuery("(min-width: 1360px)");
    const isMobile = !useMediaQuery("(min-width: 960px)");

    const formId = props.match.params.form_id;

    // const [currHighlight, setCurrHighlight] = useState(null);

    const [threads, setThreads] = useState(null);

    const [loadingSidebar, setLoadingSidebar] = useState(false);
    const [loadingNewHighlight, setLoadingNewHighlight] = useState(false);

    useEffect(async () => {
        let { session_id } = props.match.params;

        if (!isOpen || !session_id) return;

        // Set loading
        if (threads) setLoadingNewHighlight(true);
        else setLoadingSidebar(true);

        const selection = getSelection();

        // We have highlighted some text
        if (selection && selection.rangeCount > 0 && selection.type != "Caret") {
            let annotationId = uuidv4();

            const range = selection.getRangeAt(0);
            let offSet = getOffsetCalc(range);

            const currH = {
                startIndex: range.startOffset + offSet,
                endIndex: range.endOffset + offSet,
                itemId: range.startContainer.parentNode.id,
                text: newLineToBreak(selection.toString()),
            };

            send_request_graphql(formId, CREATE_ANNOTATION_AND_RETURN_ALL(annotationId, formId, session_id, currH.itemId, currH.startIndex, currH.endIndex, currH.text, username))
                .then((res) => {
                    let latestAnnotations = res.data.createAnnotationAndReturnAll;
                    let createdAnnotation = latestAnnotations[0];

                    addHighlight(createdAnnotation, showSidebarAndHighlight, annotationId);
                    setThreads(latestAnnotations);

                    updateState({ annotationCount: annotationCount + 1, highlightedAnnotation: annotationId });

                    setLoadingNewHighlight(false);
                    setLoadingSidebar(false);
                })
                .catch((e) => {
                    setLoadingNewHighlight(false);
                    setLoadingSidebar(false);
                });
        } else {
            // We have either manually opened the sidebar or clicked on a highlight

            // We have opened the sidebar menu manually
            send_request_graphql(formId, GET_ALL_ANNOTATIONS(formId, session_id))
                .then((res) => {
                    let annotations = res.data.getAnnotationsByFormIdAndFormSessionId;

                    setThreads(annotations);
                    setLoadingNewHighlight(false);
                    setLoadingSidebar(false);
                })
                .catch((e) => {
                    console.log(e);
                    setLoadingNewHighlight(false);
                    setLoadingSidebar(false);
                });
        }
    }, [isOpen, rerenderSidebar]);

    const getLoader = (i, minHeight) => {
        return (
            <List>
                <StyledSkeleton id={`loading-skeleton-${i}`} variant="rect" minHeight={minHeight} />
            </List>
        );
    };

    const drawerStyles = {
        zIndex: 250,
        borderLeft: "none",
        paddingTop: isMobile ? "0px" : "64px",
        height: isMobile ? "calc(100% - 56px)" : "fit-content",
        width: isMobile ? "100%" : "25%",
        maxWidth: isMobile ? "none" : "400px",
        maxHeight: "100%",
        background: "none !important",
    };

    return (
        <StyledDrawer
            anchor={isMobile ? "bottom" : "right"}
            open={isOpen}
            PaperProps={{
                style: drawerStyles,
            }}
            variant="persistent"
        >
            <InsideDiv id={"thread-side-bar"}>
                {loadingNewHighlight && getLoader(0, "225px")}
                {loadingSidebar && [...Array(annotationCount)].map((i, idx) => getLoader(idx, "225px"))}
                {threads &&
                    threads.map((t) => {
                        return (
                            <List id={t.annotationId} key={t.annotationId}>
                                <ThreadBox
                                    children={
                                        <ThreadBoxContent
                                            thread={t}
                                            timezone={timezone}
                                            username={username}
                                            threads={threads}
                                            setThreads={setThreads}
                                            updateState={updateState}
                                            annotationCount={annotationCount}
                                            formId={formId}
                                        />
                                    }
                                    isFocused={t.annotationId == highlightedAnnotation}
                                    isMobile={isMobile}
                                />
                            </List>
                        );
                    })}
            </InsideDiv>
        </StyledDrawer>
    );
}

export default ThreadSidebar;
