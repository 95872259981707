export const styles = (theme) => ({
  root: {
    width: "100%",
  },
  progressbar: {
    width: "100%",
    margin: "auto",
    [theme.breakpoints.up(900 + theme.spacing.unit * 2 * 2)]: {
      width: "100%",
    },
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  textroot: {
    width: "95%",
    margin: "10px",
  },
  SearchButton: {
    position: "relative",
    left: 0,
    marginLeft: 20,
    color: "#2196F3",
    border: "1px solid #2196F3",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "#2196F3",
    },
  },
  defaultButton: {
    boxShadow: "none",
  },
  SaveButton: {
    position: "relative",
    left: 0,
    marginLeft: 20,
    color: "white",
    boxShadow: "none",
    backgroundColor: "#2196F3",
    border: "1px solid #2196F3",
    "&:hover": {
      color: "#2196F3",
      border: "1px solid #2196F3",
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
  Button: {
    marginTop: "1.25em",
    boxShadow: "none",
    color: "#2196F3",
    border: "1px solid #2196F3",
    backgroundColor: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "#2196F3",
    },
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  textField: { width: "100%", margin: "auto", position: "relative" },
  layout: {
    // minHeight: "90vh",
    width: "100%",
    marginTop: 0,
    margin: "auto",
    padding: "1rem",
    maxWidth: 1500,

    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      minWidth: 700,
    },
  },
  paper: {
    minHeight: "80vh",
    boxShadow: "none",
    borderRadius: "8px",
    border: "1px solid #E5E7EB",
    boxShadow: "0px 2px 4px 0px rgba(16, 24, 40, 0.06)",
    minHeight: "unset",
    padding: "40px"
  },
  progress: {
    margin: theme.spacing.unit * 2,
    marginTop: "10px",
    color: "#2196F3",
    width: 54,
  },
  layoutPreview: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background: "white",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    maxWidth: "layoutPreview",
  },
  paperPreview: {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    marginTop: "80px",
  },
  contentPreview: {
    width: "100%",
    maxWidth: "1140px",
    padding: ".5rem 1rem",
  },
  footerPreview: {
    background: "#E10050",
    color: "#fff",
    padding: "10px 0",
  },
  readOnlyTopBar: {
    height: "47px",
    backgroundColor: "#484848",
  },
  terminatedReadOnlyTopBar: {
    height: "47px",
    backgroundColor: "#B00020",
  },
  readOnlyWarning: {
    color: "#FFFFFF",
    padding: "8px 0px",
    fontSize: "14px !important",
    marginBottom: "4px"
  },
  terminatedReadOnlyWarning: {
    color: "#FFFFFF",
    padding: "8px 0px",
    fontSize: "14px !important",
    marginBottom: "4px"
  },
  annotationBadge: {
    zIndex: "350 !important",
    transform: "scale(1) translate(25%, -25%)",
    "&:hover": {
      cursor: "pointer",
    },
  },
  errorAlert: {
    backgroundColor: "#F7E5E9",
    color: "black",
  },
  infoAlert: {
    color: "black",
    borderColor: "black",
    backgroundColor: "#E5E5E5",
  },
});
