import React, { Component } from "react";
import { Auth } from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import { Button as ButtonUI, CircularProgress } from '@material-ui/core';
import "./customLogin.css";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import GoogleIcon from "./component/Icon/GoogleIcon";
import axios from "axios";
import config from "../config";

const AUTH_CONFIG = {
  mandatorySignIn: true,
  authenticationFlowType: 'USER_PASSWORD_AUTH'
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class CustomSignIn extends Component {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {
      username: "",
      resetUserName: "",
      password: "",
      errorMessage: "",
      openError: false,
      formState: "signIn",
      newPassword: "",
      user: {},
      pageProcessing: false,
      loading: false
    };

    // Configure Auth once during initialization
    Auth.configure({
      ...AUTH_CONFIG,
      region: config.REGION,
      userPoolId: config.USER_POOL_ID,
      userPoolWebClientId: config.APP_CLIENT_ID,
      responseType: 'code',
      domain: config.DOMAIN,
      
    });
  }

  componentDidMount = async () => {
    this.setState({
      username: "",
      password: "",
      formState: "signIn",
      resetUserName: "",
      newPassword: "",
    });

    // Set up session refresh
    this.refreshInterval = setInterval(this.refreshSession, 4 * 60 * 1000);

    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        this.props.onStateChange("signedIn", {});
        this.updateLastLogged();
      }
    } catch (err) {
      console.log("No existing session:", err);
    }
  };

  componentWillUnmount() {
    // Clear interval when component unmounts
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  handleResetPassword = () => {
    window.location.href = "https://app.workflow86.com/forgotPassword";
  };

  handleSetNewPassword = () => {
    this.setState({ formState: "setNewPassword" });
  };

  handleFirstTimeSignIn = () => {
    this.setState({ formState: "changePassword" });
  };

  handleFormSubmission = (event) => {
    event.preventDefault();
    if (this.state.formState === "signIn") {
      this.signIn();
    } else if (this.state.formState === "resetPassword") {
      this.resetPassword();
    } else if (this.state.formState === "setNewPassword") {
      this.setNewPassword();
    } else if (this.state.formState === "changePassword") {
      this.changePassword();
    }
  };

  resendCode = () => {
    this.resetPassword();
  }

  resetPassword = () => {
    const resetUserName = this.state.resetUserName;
    Auth.forgotPassword(resetUserName)
      .then(() => {
        this.setState({ formState: "setNewPassword" });
      })
      .catch(error => {
        this.setState({ openError: true });
        this.setState({ errorMessage: error.message });
      });
  };

  setNewPassword = () => {
    const resetUserName = this.state.resetUserName;
    const code = this.state.code;
    const newPassword = this.state.newPassword;
    Auth.forgotPasswordSubmit(resetUserName, code, newPassword)
        .then(() => {
              Auth.signIn(resetUserName, newPassword).then(r => {
                Auth.signOut({global: true}).then(r => {
                  console.log("Initiating global logout");
                  this.setState({
                    formState: "signIn",
                    resetUserName: "",
                    newPassword: ""
                  });
                  window.location.href = "/";
                })
              })
            }
        )
        .catch((error) => {
          this.setState({ openError: true });
          this.setState({ errorMessage: error.message });
        });
  };

  changePassword = () => {
    Auth.completeNewPassword(
      this.state.user,               // the Cognito User Object
      this.state.newChangePassword,       // the new password
    ).then(user => {
      this.updateLastLogged();
      // at this time the user is logged in if no MFA required
      console.log(user);
      this.props.onStateChange("signedIn", {});
    }).catch(error => {
      this.setState({ openError: true });
      this.setState({ errorMessage: error.message });
    });
  };

  signIn = async () => {
    this.setState({
      formState: "signIn",
      resetUserName: "",
      newPassword: "",
      pageProcessing: true
    });
    
    const username = this.state.username;
    const password = this.state.password;

    try {
      // Attempt sign in
      const user = await Auth.signIn(username, password);
      console.log("Sign in response:", user);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log("New password required");
        this.setState({
          formState: "changePassword",
          user: user,
          pageProcessing: false
        });
        return;
      }

      // Verify session
      const session = await Auth.currentSession();
      if (!session.isValid()) {
        throw new Error("Invalid session after sign in");
      }
      
      this.props.onStateChange("signedIn", {});
      this.setState({
        pageProcessing: false
      });

      await this.updateLastLogged();

    } catch (error) {
      console.error("Sign in failed:", {
        code: error.code,
        name: error.name,
        message: error.message
      });
      
      this.setState({ 
        openError: true,
        errorMessage: error.message || "Sign in failed. Please try again.",
        pageProcessing: false
      });
    }
  }

  updateLastLogged = async () => {
    try {
      console.log("Starting updateLastLogged request");
      const json = await this.send_request(
        `update-last-logged`, 
        "", 
        {},
        'POST'
      );
      
      if (!json) {
        console.log("UpdateLastLogged request returned null");
      } else {
        console.log("UpdateLastLogged response:", json);
      }
    } catch (err) {
      console.log("UpdateLastLogged error details:", {
        code: err.code,
        name: err.name,
        message: err.message,
        stack: err.stack
      });
    }
  }

  serializeData = (formBodyObj) => {
    const requestBody = Object.keys(formBodyObj)
        .map((key) => {
          return (
              encodeURIComponent(key) + "=" + encodeURIComponent(formBodyObj[key])
          );
        })
        .join("&");

    return requestBody;
  }

  send_request = async(
    path,
    bodydata,
    querydata,
    method = 'POST',
    headers = { Accept: "application/json", "Content-Type": "application/json" },
    responseType,
    onUploadProgress = null
  ) => {
    const url = `${config.USER_ADDRESS}/${path}`;
    console.log("Making request to:", url);

    try {
      console.log("Getting current session...");
      const session = await Auth.currentSession();
      
      if (!session.isValid()) {
        throw new Error("Invalid session");
      }

      headers.Authorization = session.getIdToken().getJwtToken();
      console.log("Token obtained");

      console.log("Sending request...");
      const response = await axios({
        url,
        method,
        data: bodydata,
        headers,
        responseType,
        onUploadProgress,
        timeout: 28000
      });
      
      console.log("Request successful:", {
        status: response.status,
        hasData: response.data ? true : false
      });
      return response;
    } catch (err) {
      console.error("Request failed:", {
        status: err.response && err.response.status,
        message: err.message,
        data: err.response && err.response.data,
        stack: err.stack
      });
      throw err;
    }
  }

  handleClose = () => {
    this.setState({ openError: false });
  };

  backToSignIn = () => {
    this.setState({ formState: "signIn" });
  };

  handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const check_type = ["radio", "checkbox"].includes(type);
    this.state[name] = check_type ? checked : value;
    this.state["checkedValue"] = check_type ? value : null;
  };

  signInWithGG = async () => {
    this.setState({loading: true})
    const formUrl = window.location.pathname;
    await Auth.federatedSignIn({provider: 'Google', customState: formUrl}).then(async (user) => {
      setTimeout(() => {
        this.setState({loading: false})
      },20000)
    })
  }

  // Add this method to handle token refresh
  refreshSession = async () => {
    try {
      const session = await Auth.currentSession();
      if (!session.isValid()) {
        console.log("Session invalid, signing out");
        await Auth.signOut();
        window.location.reload();
      }
    } catch (err) {
      console.log("Session refresh error:", err);
    }
  }

  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.openError}
          autoHideDuration={6000}
          onClose={this.handleClose}
          key={"top" + "center"}
        >
          <Alert onClose={this.handleClose} severity="error">
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
        <Grid container spacing={2} className="main-page">
          <Grid item xs={12} sm={12} className="main-form-login">
            {this.state.formState === "signIn" ? (
              <div className="mainDiv">
                {this._validAuthStates.includes(this.props.authState) && (
                  <form onSubmit={this.handleFormSubmission} >
                    <Typography component="div">
                      <div><img src="/svgs/logo.svg" alt="logo" className="logo-image" /></div>
                      <h4>Sign in to access this form</h4>
                    </Typography>
                    <Typography component="div" className="login-with-app-another">
                      <div>
                        <ButtonUI
                          variant="contained"
                          color="secondary"
                          className="sign-in-with-gg"
                          startIcon={<GoogleIcon/>}
                          onClick={this.signInWithGG}
                          disabled={this.state.loading}
                        >
                          Sign in with Google
                          {this.state.loading && (
                            <CircularProgress size={24} className="buttonProgress" />
                          )}
                        </ButtonUI>
                      </div>
                      {/*<div> <ButtonUI variant="contained" color="primary" startIcon={<FacebookIcon />} > SIGN IN WITH FACEBOOK </ButtonUI> </div>*/}
                    </Typography>
                    <div className="inputArea">
                      <p className="inputDescription">Username</p>
                      <TextField
                        id="username"
                        key="username"
                        name="username"
                        className="input-field"
                        placeholder="Enter your username or email"
                        variant="outlined"
                        type="text"
                        onChange={this.handleInputChange}
                      />
                      <p className="inputDescription">Password</p>
                      <TextField
                        style={{ width: "100%" }}
                        placeholder="Enter your password"
                        variant="outlined"
                        id="password"
                        key="password"
                        name="password"
                        type="password"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="actionArea">
                      <Typography>
                        <Link href="#" onClick={this.handleResetPassword}>
                          Forgot your password?
                        </Link>
                        {/* <Link href="#" onClick={this.handleResetPassword}>
                          I don't have an account
                        </Link> */}
                      </Typography>
                      <div style={{ marginTop: 24 }}>
                        <ButtonUI
                            className="signInButton"
                            type="submit"
                            onClick={this.handleFormSubmission}
                            disabled={this.state.pageProcessing}
                            variant="contained"
                        >
                          Sign In
                          {this.state.pageProcessing && (
                            <CircularProgress className="buttonProgress" size={20} />
                          )}
                        </ButtonUI>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            ) : (
                <div className="mainDiv">
                  {this._validAuthStates.includes(this.props.authState) && (
                    <form onSubmit={this.handleFormSubmission} >
                      {this.state.formState === "changePassword" ? (
                        <React.Fragment>
                          <Typography component="div">
                            <div><img src="/svgs/logo.svg" alt="logo" className="logo-image" /></div>
                            <h4>Change Password</h4>
                          </Typography>
                          <div className="inputArea">
                            <p className="inputDescription">New Password</p>
                            <TextField
                              id="newChangePassword"
                              key="newChangePassword"
                              name="newChangePassword"
                              style={{ width: "100%" }}
                              placeholder="New Password"
                              variant="outlined"
                              type="password"
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="actionArea">
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <ButtonUI style={{ marginRight: "10px" }} theme={"S_blue"} onClick={this.backToSignIn}> BACK </ButtonUI>
                                <ButtonUI theme={"P_blue"} type="submit" onClick={this.handleFormSubmission}> CHANGE </ButtonUI>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                      {this.state.formState === "resetPassword" ? (
                        <React.Fragment>
                          <Typography component="div">
                            <div><img src="/svgs/logo.svg" alt="logo" className="logo-image" /></div>
                            <h4>Reset your Password</h4>
                          </Typography>
                          <div className="inputArea">
                            <p className="inputDescription">Username</p>
                            <TextField
                              id="resetUserName"
                              key="resetUserName"
                              name="resetUserName"
                              style={{ width: "100%" }}
                              placeholder="Enter your username"
                              variant="outlined"
                              type="text"
                              onChange={this.handleInputChange}
                            />
                            <p className="inputDescription">
                              We will send a password reset code to the email
                                connected to the user account. </p>
                          </div>
                          <div className="actionArea">
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <ButtonUI style={{ marginRight: "10px" }} className="backButton" theme={"S_blue"} onClick={this.backToSignIn}>
                                    BACK
                                </ButtonUI>
                                <ButtonUI className="resetButton" theme={"P_blue"} type="submit" onClick={this.handleFormSubmission}>
                                    SEND CODE
                                </ButtonUI>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                      {this.state.formState === "setNewPassword" ? (
                        <React.Fragment>
                          <Typography component="div">
                            <div><img src="/svgs/logo.svg" alt="logo" className="logo-image" /></div>
                            <h4>Reset your Password</h4>
                          </Typography>
                          <div className="inputArea">
                            <p className="inputDescription">Code</p>
                            <TextField
                              id="code"
                              key="code"
                              name="code"
                              style={{ width: "100%" }}
                              placeholder="Code"
                              variant="outlined"
                              type="text"
                              onChange={this.handleInputChange}
                            />
                            <p className="inputDescription">New Password</p>
                            <TextField
                              style={{ width: "100%" }}
                              placeholder="Enter your password"
                              variant="outlined"
                              id="newPassword"
                              key="newPassword"
                              name="newPassword"
                              type="password"
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="actionArea">
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <ButtonUI style={{ marginRight: "10px" }} className="backButton" theme={"S_blue"} onClick={this.resendCode}>
                                    Resend Code
                                </ButtonUI>
                                <ButtonUI className="resetButton" theme={"P_blue"} type="submit" onClick={this.handleFormSubmission}>
                                    Submit
                                </ButtonUI>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                    </form>
                  )}
                </div>
              )}

          </Grid>
        </Grid>
      </div>
    );
  }
}
