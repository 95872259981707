import React from "react";
import { Grid, List, Paper } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { styled } from "@material-ui/core/styles";

const StyledSkeleton = styled(Skeleton)({
    background: "#E8E8E8",
    borderRadius: "4px",
});

const NavigationGrid = styled(Grid)({
    marginRight: "16px !important",
});

function FormSkeletonLoader({ classes }) {
    return (
        <Grid container spacing={1} className={classes.layout}>
            {/* Header skeletons */}
            <Grid item xs={12} sm={8} md={8}>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <StyledSkeleton variant="rectangular" width={120} height={40} />
                    </Grid>
                    <Grid item>
                        <StyledSkeleton variant="rectangular" width={200} height={24} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                <Grid 
                    container 
                    spacing={1}
                    justifyContent="flex-end"
                >
                    <Grid item>
                        <StyledSkeleton variant="rectangular" width={100} height={36} />
                    </Grid>
                    <Grid item>
                        <StyledSkeleton variant="rectangular" width={100} height={36} />
                    </Grid>
                </Grid>
            </Grid>

            {/* Left navigation menu skeleton */}
            <NavigationGrid item xs={12} sm={2}>
                <Grid container spacing={1} className="navigation-loader">
                    {[1, 2, 3].map((index) => (
                        <Grid item xs={4} sm={12} key={index}>
                            <StyledSkeleton 
                                variant="rectangular" 
                                height={48} 
                                sx={{ 
                                    borderRadius: "8px",
                                    marginBottom: { xs: 0, sm: 1 }
                                }} 
                            />
                        </Grid>
                    ))}
                </Grid>
            </NavigationGrid>

            {/* Main content */}
            <Grid item xs={12} sm={10} md={8}>
                <Paper 
                    className={classes.paper} 
                    sx={{ 
                        minHeight: "unset",
                        padding: { xs: 2.5, sm: 5 },
                        borderRadius: "8px",
                        border: "1px solid #E5E7EB",
                        boxShadow: "0px 2px 4px 0px rgba(16, 24, 40, 0.06)"
                    }}
                >
                    <StyledSkeleton variant="rectangular" height={40} style={{ marginBottom: 24 }} />
                    <StyledSkeleton variant="rectangular" height={120} style={{ marginBottom: 24 }} />
                    <StyledSkeleton variant="rectangular" height={80} style={{ marginBottom: 24 }} />
                    <StyledSkeleton variant="rectangular" height={200} style={{ marginBottom: 32 }} />

                    <Grid container justifyContent="space-between" sx={{ mt: 4 }}>
                        <StyledSkeleton variant="rectangular" width={100} height={36} />
                        <StyledSkeleton variant="rectangular" width={100} height={36} />
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default FormSkeletonLoader;
