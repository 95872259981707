import React, { Component } from "react";
import axios from "axios";
import config from "../config";

import {
  Grid, Button, CircularProgress, styled
} from "@material-ui/core";

import CopyToClipboard from "react-copy-to-clipboard";
import { ArrowBack, Beenhere, Refresh } from "@material-ui/icons";
import { ResponseBox, Text } from "./component/NavigationSidebar/CommonComponents";
import { withTheme } from '@material-ui/core/styles';

class FormWaiting extends Component {
  state = {
    poolCount:0
  };
  
  constructor(props) {
    super(props);
        
  }

  queryNextForm = async () => {
    
    const controller = this.props.isTest ? "/find_next_test_form?" : "/find_next_form?";

    await axios(
      config.API_ADDRESS +
      controller +
      "form_id="+this.props.match.params.form_id + "&" +
      "session_id="+this.props.match.params.session_id + "&" +
      "project_session_id="+this.props.match.params.project_session_id,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
      }
    )
    .then((response) => {

      const formRoute = this.props.isTest ? "form/form_test" : "form";

      if(response.data && response.data.data &&
         response.data.data.formId && response.data.data.formSessionId) {
        if (this.props.isTest) {
          this.props.history.push(
            `/${formRoute}/${response.data.data.formId}/${this.props.match.params.test_id}/${response.data.data.formSessionId}`
          );
        } else {
          this.props.history.push(
            `/${formRoute}/${response.data.data.formId}/${response.data.data.formSessionId}`
          );
        }
      } 
      this.setState({poolCount:this.state.poolCount+1});
    });
  }

  async componentDidMount() {
    this.timer = setInterval(async ()=>await this.queryNextForm(), 1000);    
  }

  componentDidUpdate() {
    
    if(this.state.poolCount > 30) {
      clearInterval(this.timer);
      this.timer = null;   
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null; 
  }

  render() {

    const handleBack = () => {  
      let formSessionURL = `/form/${this.props.match.params.form_id}/${this.props.match.params.session_id}`;
    
      if (this.props.isTest) {
        const testId = this.props.match.params.test_id;
        formSessionURL = `/form/form_test/${this.props.match.params.form_id}/${testId}/${this.props.match.params.session_id}`;
      }
  
      this.props.history.push(formSessionURL);
    }

    if(this.state.poolCount <= 30) {
      return (
        <Grid container align="center" justify="center" spacing={3}
          style={{marginTop:"100px",maxWidth:"540px", display:"inline-flex", padding: "0px 24px"}}> 

          <Grid item xs={12}>
            <CircularProgress size={40} />
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <ResponseBox item xs={12} boxShadow borderColor="rgba(33, 150, 243, 1)" bgColor="rgba(233, 245, 254, 1)">
                  <Grid container spacing={2} style={{ textAlign: "left"}}>
                      <Grid item xs={12}>
                          <Text bold>⏭ Loading next section...</Text>
                      </Grid>
                      <Grid item xs={12}>
                          <Text>Your response has been submitted and the next page is being loaded. If you want to close this window, click Save Page Link so you can come back to this page at a later time.</Text>
                      </Grid>
                  </Grid>
              </ResponseBox>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Button color="primary" variant="outlined" size="large" startIcon={<ArrowBack />} onClick={handleBack}>
                  Back
                </Button>
              </Grid>
              <Grid item>
                <CopyToClipboard text={window.location.href}>
                  <Button color="primary" variant="outlined" size="large" startIcon={<Beenhere />}>
                    Save Page Link
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      );
    } else {
      return (
        <Grid container align="center" justify="center" spacing={3} style={{marginTop:"100px",maxWidth:"540px", display:"inline-flex", padding: "0px 24px"}}> 
          <Grid item xs={12}>
            <Grid container>
              <ResponseBox item xs={12} boxShadow borderColor="rgba(0, 0, 0, 1)" bgColor="rgba(242, 242, 242, 1)">
                  <Grid container spacing={2} style={{ textAlign: "left"}}>
                      <Grid item xs={12}>
                          <Text bold>⏸ Next page is taking a long time to load...</Text>
                      </Grid>
                      <Grid item xs={12}>
                          <Text>This may be because the next page has not been generated yet, or an error has occurred preventing it from loading. We’ve paused trying to load it for now, but you can click Retry to retry the loading process. You can also save this page link to try again at another </Text>
                      </Grid>
                  </Grid>
              </ResponseBox>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Button color="primary" variant="outlined" size="large" startIcon={<ArrowBack />} onClick={handleBack}>
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" variant="outlined" size="large" startIcon={<Refresh />} onClick={() => { window.location.reload(); }}>
                  Retry
                </Button>
              </Grid>
              <Grid item>
                <CopyToClipboard text={window.location.href}>
                  <Button color="primary" variant="outlined" size="large" startIcon={<Beenhere />}>
                    Save Page Link
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      )
    }
  }

}

export default withTheme(FormWaiting);