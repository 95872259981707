import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000',
      light: '#B2B2B2',
      dark: '#000',
      contrastText: '#fff'
    },
    secondary: {
      main: '#000',
      light: '#B2B2B2',
      dark: '#000',
      contrastText: '#fff'
    },
    error: {
      main: '#f44336'
    },
    grey: {
      100: '#FAFAFA',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    background: {
      default: '#fff',
      paper: '#fff'
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      }
    },
    MuiDialogActions: {
      root: {
        padding: '16px 24px !important',
        justifyContent: 'flex-end',
        gap: '8px'
      }
    }
  }
}); 