import React from "react";
import {
  Grid,
  Snackbar,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopy from "@material-ui/icons/FileCopy";
import { withStyles } from '@material-ui/core/styles';
import IconBanner from "./Icon/IconBanner";

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/Check';
import { FormFailuresText, getFailedUploadsBanner } from "./util/SubmissionUtil";

const styles = {
  snackbar: {
    maxWidth: '500px',
    width: '90%',
    margin: '0 auto'
  },
  snackbarContent: {
    backgroundColor: 'white',
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid #e0e0e0',
    '@media (max-width: 600px)': {
      padding: '12px',
    }
  },
  copyBtn: {
    cursor: 'pointer',
    '&:hover': {
      color: '#2196f3'
    }
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '@media (max-width: 600px)': {
        fontSize: '14px'
      }
    }
  }
}

class SaveDialog extends React.Component {

  getFileUploadsBanner = (failedSave, failedUploads) => {
    const hasFailedUploads = failedUploads && Object.keys(failedUploads).length > 0;

    if (hasFailedUploads) return getFailedUploadsBanner(failedUploads);
    else if (!failedSave) {
      return <MuiAlert severity="success">Uploaded files saved</MuiAlert>;
    }

    return <></>;
  }

  render() {
    const { classes, formFailures, open, close } = this.props;
    const { failedUploads, failedSave } = formFailures;

    return (
      <Snackbar
        open={open}
        onClose={close}
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Grid container spacing={2} className={classes.snackbarContent}>
          <Grid item xs={12}>
            {!failedSave ? (
              <MuiAlert severity="success">Form data saved</MuiAlert>
            ) : (
              <MuiAlert severity="error">Form data could not be saved. Please try again.</MuiAlert>
            )}
          </Grid>
          <Grid item xs={12}>
            {this.getFileUploadsBanner(failedSave, failedUploads)}
          </Grid>
          {!failedSave && (
            <>
              <Grid item xs={12}>
                <TextField
                  id="copyAddress"
                  className={`${classes.textField} copy-text`}
                  fullWidth={true}
                  value={window.location.href}
                  variant="outlined"
                  size="small"
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyToClipboard text={window.location.href}>
                          <FileCopy className={classes.copyBtn} />
                        </CopyToClipboard>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Snackbar>
    );
  }
}

export default withStyles(styles)(SaveDialog)