import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
} from "@material-ui/core";
import { Button } from "vardogyir-ui";
import { useTheme } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RequiredQuestionAlert() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      onExited={() => {
        document.documentElement.scrollTop = 0;
      }}
    >
      <DialogTitle>
        <Grid container item xs={12} direction={"row"} alignItems={"center"} spacing={1}>
          <i 
            className="material-icons error resize-icon" 
            style={{ color: theme.palette.error.main }}
          >
            error
          </i>
          <h5 style={{ 
            margin: 0,
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily 
          }}>
            Please answer all required questions
          </h5>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <p style={{ 
          color: theme.palette.text.secondary,
          fontFamily: theme.typography.fontFamily 
        }}>
          All required questions must be answered before proceeding.
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          theme={"P_blue"}
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
