import { Button, Tooltip } from "@material-ui/core";
import { Autorenew } from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";
import RateReviewIcon from "@material-ui/icons/RateReview";
import RateReviewRoundedIcon from "@material-ui/icons/RateReviewRounded";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import ChatIcon from "@material-ui/icons/Chat";
import { withStyles } from "@material-ui/core/styles";

export const AutoSaveTooltip = withStyles((theme) => ({
    tooltip: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        fontSize: "12px",
    },
}))(Tooltip);

export const StyledSaveIcon = styled(Autorenew)(({ theme }) => ({
    color: "#2196F3",
    marginRight: "10px",
    height: "22px",
    width: "auto",
}));

export const AnnotateIcon = styled(RateReviewIcon)(({ color }) => ({
    color: color,
    marginRight: "2px",
    fontSize: "20px",
    width: "auto",
    "&:hover": {
        cursor: "pointer",
    },
}));

export const Chat = styled(ChatIcon)(({ color }) => ({
    color: color,
    marginRight: "2px",
    fontSize: "20px",
    width: "auto",
    "&:hover": {
        cursor: "pointer",
    },
}));

export const AnnotateSideIcon = styled(RateReviewRoundedIcon)(({ color }) => ({
    color: color,
    height: "24px",
    width: "auto",
    position: "relative",
    "&:hover": {
        cursor: "pointer",
    },
}));

export const AnnotateAvatar = styled(Avatar)((props) => {
    let right = "1%";

    if (props.isOpen && props.annotationCount && props.annotationCount > 0) {
        if (props.highlightedAnnotation) right = "calc(400px + 15px)";
        else right = "400px";
    }

    return {
        backgroundColor: props.isOpen ? "#2196F3" : theme => theme.palette.grey[200],
        position: "fixed",
        top: "50%",
        zIndex: "350 !important",
        marginRight: "2px",
        right: right,
        borderRadius: "8px",
        "&:hover": {
            cursor: "pointer",
        },
    };
});

export const AnnotateButton = styled(Button)(({ isAnnotationModeOn, theme }) => ({
    border: isAnnotationModeOn ? "1px solid #2196F3" : `1px solid ${theme.palette.primary.main}`,
    background: isAnnotationModeOn ? "#2196F3" : theme.palette.background.default,
    color: isAnnotationModeOn ? theme.palette.primary.contrastText : theme.palette.primary.main,
    padding: "6px 16px",
    fontSize: "0.75rem",
    marginRight: "8px",
    "&:hover": {
        color: isAnnotationModeOn ? "rgba(255, 255, 255, 0.85)" : "#2196F3",
        background: isAnnotationModeOn ? "rgba(33, 150, 243, 0.85)" : theme.palette.background.default,
        border: isAnnotationModeOn ? "1px solid rgba(33, 150, 243, 0.85)" : "1px solid #2196F3",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    "&:disabled": {
        border: `1px solid ${theme.palette.text.disabled}`
    },
    "*:focus": {
        outline: "none !important",
    },
}));

export const ShowAnnotateButton = styled(Button)(({ isThreadSidebarOpen, theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    background: isThreadSidebarOpen ? theme.palette.primary.main : theme.palette.grey[200],
    color: isThreadSidebarOpen ? theme.palette.primary.contrastText : theme.palette.primary.main,
    padding: "6px 16px",
    fontSize: "0.75rem",
    marginRight: "8px",
    "&:hover": {
        color: isThreadSidebarOpen ? theme.palette.grey[200] : theme.palette.primary.main,
        background: isThreadSidebarOpen ? "rgba(0, 0, 0, 0.85)" : theme.palette.grey[100],
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    "&:disabled": {
        border: `1px solid ${theme.palette.text.disabled}`
    },
    "*:focus": {
        outline: "none !important",
    },
}));

export const StyledBadge = styled(Badge)((props) => {
    let right = "1%";

    if (props.isOpen && props.annotationCount && props.annotationCount > 0) {
        if (props.highlightedAnnotation) right = "calc(400px + 15px)";
        else right = "400px";
    }

    return {
        position: "fixed",
        backgroundColor: "#E10000",
        top: "50%",
        zIndex: "350 !important",
        right: right,
    };
});

export const TooltipText = styled("div")({
    fontSize: "12px",
});

export const AutosaveFooter = styled("div")({
    paddingTop: "30px",
    paddingBottom: "4px",
});

export const AutoSaveIcon = styled(Autorenew)({
    height: "12px",
    width: "auto",
    marginRight: "4px",
});

export const FooterText = styled("span")(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "12px",
    width: "100%",
    lineHeight: "16px",
    color: theme.palette.grey[600],
}));
