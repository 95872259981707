const aws_config_dev = {
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id:  "ap-southeast-2_Kp9DpRrdK",
  aws_user_pools_web_client_id :"5f93lfrqqh7nruhjiiin8qbeem",
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  oauth: {
    domain: 'auth.app.w86dev.click',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://form.w86dev.click',
    redirectSignOut: 'https://form.w86dev.click',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  gg_client_id: "541992205730-ombu26ut0amuo794b59bku1kt08egofm.apps.googleusercontent.com"
};

const aws_config = aws_config_dev
export default aws_config;