import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Button } from "@material-ui/core";
import SignatureCanvas from 'react-signature-canvas';

const styles = theme => ({
    root: {
        width: '100%',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
    textroot: {
        width: '100%',
        margin: 'auto'
    },
    signatureOptions: {
        marginBottom: theme.spacing(2)
    },
    textInput: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    signatureButton: {
        textTransform: 'none',
        border: '1px solid black',
        marginRight: 8,
        '&.active': {
            backgroundColor: 'black',
            color: 'white',
            border: 'none'
        },
        '&.inactive': {
            backgroundColor: 'transparent',
            color: 'black'
        }
    },
    signatureContainer: {
        width: '100%',
        maxWidth: '500px'
    },
    canvas: {
        borderColor: '#868686',
        borderStyle: 'solid',
        borderWidth: 'thin',
        borderRadius: '8px',
        backgroundColor: 'white',
        width: '100%',
        height: '120px'
    },
    signatureImage: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    placeholderContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    placeholderText: {
        color: '#868686'
    },
    clearSignatureContainer: {
        display: 'flex',
        justifyContent: 'start',
        flexDirection: 'column'
    },
    clearSignatureLink: {
        cursor: 'pointer',
        fontSize: '12px',
        color: '#868686'
    },
    disclosureText: {
        fontSize: '12px',
        marginTop: '8px'
    },
    disclosureLink: {
        color: '#0066cc'
    }
});


class ESignature extends React.Component {

    
    constructor(props) {
        
        super(props);
        this.state = {
            answer: '',
            signatureMode: 'draw', // 'draw' or 'type'
            typedName: ''
            
        };
        this.signtaurePad = React.createRef();

    }

    componentDidMount() {
        const { form, data, getDataFromComponent } = this.props;
        const { key, question_text } = data;
        const answer_data = form.answer_data[key];

        if (answer_data && answer_data.answer) {
            this.setState({ answer: answer_data.answer[0] });
            return;
        }

        if (data.data && data.data.toString() && !this.state.answer) {
            const defaultValue = data.data;
            this.setState({ answer: defaultValue });
            getDataFromComponent(
                { 'esignature_answer': defaultValue }, 
                key,
                question_text,
                defaultValue
            );
            return;
        }

        if (data.auto && data.auto.toString() && !form.answer_data[key]) {
            const autoValue = data.auto;
            this.setState({ answer: autoValue });
            getDataFromComponent(
                { 'esignature_answer': autoValue },
                key, 
                question_text,
                data.data
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //nothing to do
    }

    handleOnChange = (event) => {

      this.setState({ answer: event.target.value })
    }

    handleOnBlur = (event) => {

        let text = { 'esignature_answer': this.signtaurePad.getCanvas().toDataURL('image/jpeg') }
        this.props.getDataFromComponent(text, this.props.data.key, this.props.data.question_text, this.props.data.data)

    };

    doNothing = () => {}

    initRef = (ref) =>{

        this.signtaurePad = ref;

        if(this.signtaurePad && this.state.answer)
            this.signtaurePad.fromDataURL(this.state.answer);

        if(this.signtaurePad && this.props.data.read_only)
            this.signtaurePad.off();
            
    }

    handleTypedNameChange = (event) => {
        const typedName = event.target.value;
        this.setState({ typedName }, () => {
            const response = { 'esignature_answer': '' };
            
            if (typedName) {
                this.convertTextToImage();
                return;
            }
            
            // Clear signature if text is empty
            this.setState({ answer: '' });
            this.props.getDataFromComponent(
                response,
                this.props.data.key,
                this.props.data.question_text, 
                this.props.data.data
            );
        });
    }

    convertTextToImage = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 120;

        // Set white background
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Configure text style
        ctx.fillStyle = 'black';
        ctx.font = '32px cursive';
        ctx.textBaseline = 'middle';

        // Center the text
        const text = this.state.typedName;
        const textMetrics = ctx.measureText(text);
        const x = (canvas.width - textMetrics.width) / 2;
        const y = canvas.height / 2;

        ctx.fillText(text, x, y);

        // Convert to base64 and save
        const dataUrl = canvas.toDataURL('image/jpeg');
        this.setState({ answer: dataUrl });
        let response = { 'esignature_answer': dataUrl };
        this.props.getDataFromComponent(response, this.props.data.key, this.props.data.question_text, this.props.data.data);
    }

    clearSignature = () => {
        // Clear drawn signature
        if (this.signtaurePad) {
            this.signtaurePad.clear();
        }
        
        // Clear typed signature and answer
        this.setState({ typedName: '', answer: '' });
        
        // Notify parent component
        let response = { 'esignature_answer': '' };
        this.props.getDataFromComponent(response, this.props.data.key, this.props.data.question_text, this.props.data.data);
    }

    render() {
        const { classes, data } = this.props;
        const { signatureMode, answer } = this.state;

        try {
            return (
                <div className={classes.root}>
                    <div className={classes.signatureOptions}>
                        <Button 
                            onClick={() => this.setState({ signatureMode: 'draw' })}
                            variant={signatureMode === 'draw' ? "contained" : "outlined"}
                            className={`${classes.signatureButton} ${signatureMode === 'draw' ? 'active' : 'inactive'}`}
                        >
                            Draw
                        </Button>
                        <Button 
                            onClick={() => this.setState({ signatureMode: 'type' })}
                            variant={signatureMode === 'type' ? "contained" : "outlined"}
                            className={`${classes.signatureButton} ${signatureMode === 'type' ? 'active' : 'inactive'}`}
                        >
                            Type
                        </Button>
                    </div>

                    <div className={classes.signatureContainer}>
                        {answer ? (
                            <div className={classes.canvas}>
                                <img 
                                    src={answer} 
                                    alt="Signature Preview" 
                                    className={classes.signatureImage}
                                />
                            </div>
                        ) : (
                            signatureMode === 'draw' ? (
                                <SignatureCanvas 
                                    penColor='black'
                                    canvasProps={{
                                        className: classes.canvas
                                    }} 
                                    ref={this.initRef}      
                                    onEnd={this.props.data.read_only ? this.doNothing : this.handleOnBlur}
                                    backgroundColor="white"
                                />
                            ) : (
                                <div className={classes.canvas}>
                                    <div className={classes.placeholderContainer}>
                                        <span className={classes.placeholderText}>
                                            Signature will appear here
                                        </span>
                                    </div>
                                </div>
                            )
                        )}
                        
                        {signatureMode === 'type' && (
                            <TextField
                                className={classes.textInput}
                                fullWidth
                                label="Type your signature"
                                value={this.state.typedName}
                                onChange={this.handleTypedNameChange}
                                disabled={this.props.data.read_only}
                            />
                        )}
                        
                        <div className={classes.clearSignatureContainer}>
                            <a 
                                className={classes.clearSignatureLink}
                                onClick={this.clearSignature}
                                id={`${data.key}-clear-signature-text`}
                            >
                                Clear signature
                            </a>
                            <span className={classes.disclosureText}>
                                By signing, I agree to use{' '}
                                <a 
                                    href="https://docs.workflow86.com/docs/troubleshooting/electronic_record_and_signature_disclosure"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.disclosureLink}
                                >
                                    Electronic Records and Signatures
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            );
        } catch (e) {
            return (<h5>Error encountered</h5>)
        }

    }
}


const mapStateToProps = (state, props) => {
    return {
        form: state.form
    };
};

const mapDispatchToProps = {};


ESignature.propTypes = {
    classes: PropTypes.object.isRequired,

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ESignature));
